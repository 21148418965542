import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import * as d3 from "d3";

/**
 * WeeklyLoadChart receives an array of day objects:
 * [
 *   {
 *     workoutdate: "2025-02-05",
 *     load: 50,
 *     workouts: [ ... ]
 *   }, ...
 * ]
 * It renders a bar chart for daily loads across the week.
 */
function WeeklyLoadChart({ days, width = 300, height = 100 }) {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(width);

  useLayoutEffect(() => {
    function updateSize() {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.getBoundingClientRect().width);
      }
    }
    updateSize();

    const resizeObserver = new ResizeObserver(updateSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  // Convert data into a form suitable for D3
  const data = days.map(d => ({
    date: d.workoutdate,
    load: d.load || 0
  }));

  // We'll store a ref for the svg
  const svgRef = useRef(null);

  useEffect(() => {
    if (!data || data.length === 0) return;
    if (!svgRef.current) return;

    // Clear previous svg content
    d3.select(svgRef.current).selectAll("*").remove();

    const margin = { top: 10, right: 10, bottom: 20, left: 30 };
    const chartWidth = containerWidth - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", containerWidth)
      .attr("height", height);

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // X scale (day index)
    const xScale = d3
      .scaleBand()
      .domain(data.map((_, i) => i))
      .range([0, chartWidth])
      .padding(0.1);

    // Y scale
    const maxLoad = d3.max(data, (d) => d.load) || 1;
    const yScale = d3
      .scaleLinear()
      .domain([0, maxLoad])
      .range([chartHeight, 0])
      .nice();

    // Bars
    g.selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (_, i) => xScale(i))
      .attr("y", (d) => yScale(d.load))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => chartHeight - yScale(d.load))
      .attr("fill", "#4285F4");

    // X axis
    const xAxis = d3.axisBottom(xScale).tickFormat((_, i) => {
      // Just show day index or short date if you want
      return `Day ${i + 1}`;
    });
    g
      .append("g")
      .attr("transform", `translate(0, ${chartHeight})`)
      .call(xAxis)
      .selectAll("text")
      .style("font-size", "0.7rem");

    // Y axis
    const yAxis = d3.axisLeft(yScale).ticks(3);
    g.append("g").call(yAxis).selectAll("text").style("font-size", "0.7rem");

  }, [data, containerWidth, height]);

  return (
    <div ref={containerRef} style={{ width: "100%" }}>
      <svg ref={svgRef} />
    </div>
  );
}

export default WeeklyLoadChart;