import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useCalendarData } from "../components/calendar/subview/calendarDataProvider";
import PreviousActivitiesSection from "../components/calendar/PreviousActivitiesSection/PreviousActivitiesSection";

/**
 * Displays the list of previous activities retrieved from the calendar data.
 * Wrapped in withAuthenticationRequired for protected access.
 */
const PreviousActivitiesPage = () => {
  const { data, error, previousActivities } = useCalendarData();

  if (error) {
    return <div>Error loading previous activities: {error.message}</div>;
  }

  if (!data) {
    return <Loading />;
  }

  return (
    <div style={{ padding: "1rem", maxWidth: "1200px", margin: "0 auto" }}>
      {previousActivities && previousActivities.length > 0 ? (
        <PreviousActivitiesSection activities={previousActivities} />
      ) : (
        <p>No previous activities found.</p>
      )}
    </div>
  );
};

export default withAuthenticationRequired(PreviousActivitiesPage, {
  onRedirecting: () => <Loading />,
});