import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Button,
  Box,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth0 } from "@auth0/auth0-react";

import NotificationsBell from "./components/NotificationsBell";
import HelpMenu from "./components/HelpMenu";
import Logo from "./components/Logo";
import CustomNavLink from "./components/CustomNavLink";
import ChatButton from "../../components/settings_chatbot/components/ChatButton";
import { useChatContext } from "../../components/settings_chatbot/providers";

const NavBar = ({ accessToken }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const { isAuthenticated, logout } = useAuth0();
  const location = useLocation();
  const className = location.pathname.replace(/\//g, "");
  const theme = useTheme();
  const { openChat } = useChatContext();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.navBar.main }}
      className={`nav-container ${className}`}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Logo
            isAuthenticated={isAuthenticated}
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          />
          {isAuthenticated && (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {[
                  <MenuItem key="dashboard" onClick={handleCloseNavMenu}>
                    <CustomNavLink to="/dashboard">Dashboard</CustomNavLink>
                  </MenuItem>,
                  <MenuItem key="calendar" onClick={handleCloseNavMenu}>
                    <CustomNavLink to="/calendar">Calendar</CustomNavLink>
                  </MenuItem>,

                  <MenuItem key="activities" onClick={handleCloseNavMenu}>
                    <CustomNavLink to="/previous-activities">Activities</CustomNavLink>
                  </MenuItem>,
                  <MenuItem key="tips" onClick={handleCloseNavMenu}>
                    <CustomNavLink to="/tips">Tips</CustomNavLink>
                  </MenuItem>,
                  <MenuItem
                    key="chat"
                    onClick={() => {
                      openChat();
                      handleCloseNavMenu();
                    }}
                  >
                    Chat
                  </MenuItem>,
                  <MenuItem key="settings" onClick={handleCloseNavMenu}>
                    <CustomNavLink to="/settings">Settings</CustomNavLink>
                  </MenuItem>,
                  <MenuItem key="logout" onClick={handleLogout}>
                    Logout
                  </MenuItem>,
                ]}
              </Menu>
            </Box>
          )}

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            {isAuthenticated && (
              <>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: "block" }}
                >
                  <CustomNavLink to="/dashboard">Dashboard</CustomNavLink>
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: "block" }}
                >
                  <CustomNavLink to="/calendar">Calendar</CustomNavLink>
                </Button>

                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: "block" }}
                >
                  <CustomNavLink to="/previous-activities">Activities</CustomNavLink>
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: "block" }}
                >
                  <CustomNavLink to="/tips">Tips</CustomNavLink>
                </Button>
              </>
            )}
          </Box>

          <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <HelpMenu />
              {isAuthenticated && (
                <>
                  <NotificationsBell accessToken={accessToken} />
                  <ChatButton onClick={openChat} />
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, display: "block" }}
                  >
                    <CustomNavLink to="/settings">Settings</CustomNavLink>
                  </Button>
                  <Button
                    onClick={handleLogout}
                    sx={{
                      my: 2,
                      display: "block",
                      color: "inherit",
                    }}
                    startIcon={<LogoutIcon />}
                  >
                    Logout
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;