import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  Paper
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import WeekCard from "./components/WeekCard";

/**
 * Replaces the old tab-based approach with an Accordion approach.
 * This helps keep the UI more compact.
 * Each week is an Accordion panel you can expand for details.
 */
const WeeklyTrainingSummary = ({ trainingPlan }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = useState(false);

  if (!trainingPlan || !Array.isArray(trainingPlan) || trainingPlan.length === 0) {
    return null;
  }

  const handleChange = (weekNumber) => (event, isExpanded) => {
    setExpanded(isExpanded ? weekNumber : false);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        width: "100%",
        p: 1,
        backgroundColor: theme.palette.background.paper
      }}
    >
      <Typography
        variant="h6"
        sx={{ mb: 1, fontWeight: "bold", textAlign: "left" }}
      >
        Your Training Plan
      </Typography>

      {trainingPlan.map((week, index) => {
        const accordionId = `week-accordion-${week.weekNumber}`;
        const isWeekExpanded = expanded === week.weekNumber;

        // Format the header text, e.g. "Week 1 • 2025-02-02 - 2025-02-08"
        const weekHeader = `Week ${week.weekNumber} • ${week.weekStart} - ${week.weekEnd}`;
        return (
          <Accordion
            key={week.weekNumber}
            expanded={isWeekExpanded}
            onChange={handleChange(week.weekNumber)}
            sx={{ mb: 1 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${accordionId}-content`}
              id={`${accordionId}-header`}
            >
              <Typography variant="body1" sx={{ fontWeight: "500" }}>
                {weekHeader}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <WeekCard week={week} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Paper>
  );
};

export default WeeklyTrainingSummary;