import React from 'react';
import { Card, CardContent, Typography, Box, Divider, Grid, Chip } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SpeedIcon from '@mui/icons-material/Speed';
import BoltIcon from '@mui/icons-material/Bolt';
import EqualizerIcon from '@mui/icons-material/Equalizer';

/**
 * ActivityLoadsCard displays load metrics for an activity
 * - Shows Load (load_edited) as the primary metric with emphasis
 * - Displays other load metrics (heart_rate_load, pace_load, power_load) as secondary 
 * - Shows best power outputs when available
 * - More subtle design with reduced height for better integration
 */
const ActivityLoadsCard = ({ pace_load, load_edited, power_load, heart_rate_load, bestPowers }) => {
    // Helper function for formatting duration
    const formatDuration = (seconds) => {
        if (!seconds) return "";
        const secNum = parseInt(seconds, 10);
        if (secNum < 60) return `${secNum}s`;
        if (secNum < 3600) return `${Math.floor(secNum / 60)}m`;
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor((secNum % 3600) / 60);
        return `${hours}h ${minutes}m`;
    };

    return (
        <Box sx={{ px: 1, py: 1.5 }}>
            <Grid container spacing={2} alignItems="center">
                {/* Main Load (load_edited) - Emphasized but more subtle */}
                <Grid item xs={6} sm={3} md={3}>
                    <Box sx={{
                        px: 2,
                        py: 1.5,
                        bgcolor: 'primary.light',
                        borderRadius: 1,
                        textAlign: 'center',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Typography
                            variant="subtitle2"
                            color="primary.contrastText"
                            sx={{
                                fontWeight: 600,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <EqualizerIcon
                                fontSize="small"
                                sx={{
                                    mr: 0.5,
                                    color: 'primary.contrastText',
                                    opacity: 0.9
                                }}
                            />
                            LOAD
                        </Typography>
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            color="primary.contrastText"
                        >
                            {load_edited ? Number(load_edited).toFixed(1) : '-'}
                        </Typography>
                    </Box>
                </Grid>

                {/* Heart Rate Load */}
                {heart_rate_load !== undefined && heart_rate_load !== null && (
                    <Grid item xs={6} sm={3} md={3}>
                        <Box sx={{
                            px: 2,
                            py: 1.5,
                            bgcolor: 'grey.50',
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 500,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <FavoriteIcon
                                    fontSize="small"
                                    sx={{
                                        mr: 0.5,
                                        color: 'error.main'
                                    }}
                                />
                                HR Load
                            </Typography>
                            <Typography variant="body1">
                                {heart_rate_load ? Number(heart_rate_load).toFixed(1) : '-'}
                            </Typography>
                        </Box>
                    </Grid>
                )}

                {/* Pace Load */}
                {pace_load !== undefined && pace_load !== null && (
                    <Grid item xs={6} sm={3} md={3}>
                        <Box sx={{
                            px: 2,
                            py: 1.5,
                            bgcolor: 'grey.50',
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 500,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <SpeedIcon
                                    fontSize="small"
                                    sx={{
                                        mr: 0.5,
                                        color: 'info.main'
                                    }}
                                />
                                Pace Load
                            </Typography>
                            <Typography variant="body1">
                                {pace_load ? Number(pace_load).toFixed(1) : '-'}
                            </Typography>
                        </Box>
                    </Grid>
                )}

                {/* Power Load */}
                {power_load !== undefined && power_load !== null && (
                    <Grid item xs={6} sm={3} md={3}>
                        <Box sx={{
                            px: 2,
                            py: 1.5,
                            bgcolor: 'grey.50',
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 500,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <BoltIcon
                                    fontSize="small"
                                    sx={{
                                        mr: 0.5,
                                        color: 'warning.main'
                                    }}
                                />
                                Power Load
                            </Typography>
                            <Typography variant="body1">
                                {power_load ? Number(power_load).toFixed(1) : '-'}
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>

            {/* Best Power Outputs - More compact */}
            {bestPowers && Object.keys(bestPowers).length > 0 && (
                <Box sx={{ mt: 2, pt: 1, borderTop: 1, borderColor: 'divider' }}>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                        Best Power Outputs
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {Object.entries(bestPowers).map(([duration, power]) => (
                            <Chip
                                key={duration}
                                size="small"
                                label={`${formatDuration(parseInt(duration))}: ${power}W`}
                                sx={{
                                    bgcolor: 'grey.50',
                                    borderColor: 'divider',
                                    fontSize: '0.75rem'
                                }}
                            />
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ActivityLoadsCard;