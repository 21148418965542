import React from "react";
import { Navigate } from "react-router-dom";
import Hero from "../components/Hero";
import Content from "../components/Content";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";

function Home() {
  const { isAuthenticated, isLoading } = useAuth0();

  // While authentication status is being determined, show a loading indicator.
  if (isLoading) return <Loading />;

  // If the user is authenticated, redirect to dashboard.
  if (isAuthenticated) return <Navigate to="/dashboard" />;

  // Otherwise, render the login/landing page.
  return (
    <>
      <Hero />
      <Content />
    </>
  );
}

export default Home;