import React from 'react';
import { Box, Paper, Typography, Grid, Chip, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import WeeklyLoadChart from './WeeklyLoadChart';

/**
 * WeekCard expects a single week's data from the newTrainingPlan array, e.g.:
 * {
 *   weekNumber: 1,
 *   weekStart: "2025-02-02",
 *   weekEnd: "2025-02-08",
 *   isRecoveryWeek: false,
 *   planning: { baseLoad: 450, ... },
 *   eventDetails: { eventName, reason, weeksToEvent, ... },
 *   tssInfo: { completedWeekTSS, baselineWeeklyTSS, weeklyTargetTSS, ... },
 *   progress: { currentWeekLoad, remainingWeekLoad, weekProgressPercentage },
 *   adjustment: { adjustmentReason, unreachableLoad, ... },
 *   days: [ { workoutdate, load, workouts: [ { identifier, tss } ] }, ... ]
 * }
 */
const WeekCard = ({ week }) => {
  const theme = useTheme();

  if (!week) return null;

  const {
    isRecoveryWeek,
    planning,
    eventDetails,
    tssInfo,
    progress,
    adjustment,
    days,
  } = week;

  const dateFormat = (dateStr) =>
    moment(dateStr, 'YYYY-MM-DD').format('MMM D, YYYY');

  /**
   * A small helper to display each day's workouts
   */
  const renderDailyLoads = () => {
    if (!days || !Array.isArray(days)) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Daily Workouts
        </Typography>
        <Divider sx={{ mb: 1 }} />

        {/* Quick bar chart showing daily loads */}
        <Box sx={{ mb: 2 }}>
          <WeeklyLoadChart days={days} />
        </Box>

        <Grid container spacing={1}>
          {days.map((day) => {
            const formattedDate = dateFormat(day.workoutdate);
            return (
              <Grid item xs={12} md={6} key={day.workoutdate}>
                <Paper
                  variant="outlined"
                  sx={{ p: 1, backgroundColor: theme.palette.background.paper }}
                >
                  <Typography variant="subtitle2">
                    {day.weekday} • {formattedDate}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Load: {day.load}
                  </Typography>

                  {/* Workouts */}
                  {day.workouts && day.workouts.length > 0 && (
                    <Box sx={{ mt: 1 }}>
                      {day.workouts.map((wo, idx) => {
                        const intensityColor = getIntensityColor(wo.identifier?.intensity, theme);
                        return (
                          <Chip
                            key={idx}
                            label={`${wo.identifier?.intensity || 'N/A'} • ${wo.tss} TSS`}
                            sx={{
                              mr: 1,
                              mb: 1,
                              color: intensityColor,
                              backgroundColor: intensityColor + '22'
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      {/* Basic info row */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          {isRecoveryWeek ? 'Recovery Week' : `Week ${week.weekNumber}`}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          {week.weekStart} → {week.weekEnd}
        </Typography>
      </Box>

      {/* Event details row */}
      {eventDetails && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Event Details
          </Typography>
          <Typography variant="body1">
            <strong>Event Name:</strong> {eventDetails.eventName || 'N/A'}
          </Typography>
          <Typography variant="body1">
            <strong>Reason:</strong> {eventDetails.reason || 'N/A'}
          </Typography>
          <Typography variant="body1">
            <strong>Weeks to Event:</strong> {eventDetails.weeksToEvent}
          </Typography>
        </Box>
      )}

      {/* TSS info row */}
      {tssInfo && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            TSS Info
          </Typography>
          <Typography variant="body1">
            <strong>Baseline Weekly TSS:</strong> {tssInfo.baselineWeeklyTSS}
          </Typography>
          <Typography variant="body1">
            <strong>Weekly Target TSS:</strong> {tssInfo.weeklyTargetTSS}
          </Typography>
          <Typography variant="body1">
            <strong>Remaining Week TSS:</strong> {tssInfo.remainingWeekTSS}
          </Typography>
        </Box>
      )}

      {/* Progress row */}
      {progress && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Week Progress
          </Typography>
          <Typography variant="body1">
            <strong>Current Week Load:</strong> {progress.currentWeekLoad}
          </Typography>
          <Typography variant="body1">
            <strong>Remaining Load:</strong> {progress.remainingWeekLoad}
          </Typography>
          <Typography variant="body1">
            <strong>Progress Percentage:</strong> {progress.weekProgressPercentage}%
          </Typography>
        </Box>
      )}

      {/* Planning row */}
      {planning && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Planning
          </Typography>
          <Typography variant="body1">
            <strong>Base Load:</strong> {planning.baseLoad}
          </Typography>
        </Box>
      )}

      {/* Adjustment row */}
      {adjustment && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Load Adjustment
          </Typography>
          <Typography variant="body1">
            <strong>Adjustment Reason:</strong> {adjustment.adjustmentReason}
          </Typography>
          <Typography variant="body1">
            <strong>Unreachable Load:</strong> {adjustment.unreachableLoad}
          </Typography>
          <Typography variant="body1">
            <strong>Catch Up Mode:</strong> {adjustment.catchUpMode ? 'Yes' : 'No'}
          </Typography>
        </Box>
      )}

      {/* Daily loads */}
      {renderDailyLoads()}
    </Box>
  );
};

/**
 * Basic color mapping for intensities, e.g. "Anaerobic", "Core", etc.
 */
function getIntensityColor(intensity, theme) {
  if (!intensity) return theme.palette.grey[500];
  switch (intensity.toLowerCase()) {
    case 'anaerobic':
      return theme.palette.error.main;
    case 'vo2max':
      return theme.palette.error.dark;
    case 'tempo':
      return theme.palette.warning.main;
    case 'endurance':
      return theme.palette.success.main;
    case 'strength':
      return theme.palette.info.main;
    case 'core':
      return theme.palette.secondary.main;
    case 'rest':
      return theme.palette.text.disabled;
    default:
      return theme.palette.grey[500];
  }
}

export default WeekCard;