import React, { createContext, useContext } from "react";
import useSWR from "swr";
import { useFetcher } from "../../../utils/fetcher";
import moment from "moment";

const DataContext = createContext();

export function useCalendarData() {
  return useContext(DataContext);
}

export const CalendarDataProvider = ({ user, children }) => {
  const { fetcher, accessToken } = useFetcher();
  const { data, error } = useSWR(
    accessToken ? "https://api2.enduroco.in/getworkouts?" : null,
    fetcher
  );

  /**
   * Process the top-level workout arrays,
   * including 'workouts' (future/present data),
   * 'workoutHistory' (historical data),
   * 'previousActivities' (new data).
   */
  const addDateAndDurationToWorkouts = (data) => {
    console.log("Processing calendar data:", {
      hasWorkouts: !!data?.workouts,
      hasHistory: !!data?.workoutHistory,
      hasPreviousActivities: !!data?.previousActivities
    });

    if (!data) return data;

    const today = moment().startOf("day");

    // 1) Filter future or present workouts from 'data.workouts'
    const filteredWorkouts = (data.workouts || []).filter((day) =>
      moment(day.workoutdate, "YYYY-MM-DD").isSameOrAfter(today, "day")
    );

    // 2) Map 'filteredWorkouts'
    const updatedWorkouts = filteredWorkouts.map((day) => ({
      ...day,
      workouts: day.workouts.map((workout) => {
        const durationValue = workout.workout_doc?.duration;
        const formattedDuration =
          durationValue !== undefined
            ? `${Math.floor(durationValue / 3600)}h ${Math.floor(
                (durationValue % 3600) / 60
              )}m`
            : "";
        return {
          ...workout,
          date: day.workoutdate,
          readableDuration: formattedDuration,
          structure: workout.workout_doc
        };
      })
    }));

    // 3) Process historical workouts
    const processedHistory = data.workoutHistory
      ? data.workoutHistory.map((historyDay) => ({
          ...historyDay,
          workouts: historyDay.workouts.map((day) => ({
            ...day,
            workouts: day.workouts.map((workout) => {
              const durationValue = workout.workout_doc?.duration;
              const formattedDuration =
                durationValue !== undefined
                  ? `${Math.floor(durationValue / 3600)}h ${Math.floor(
                      (durationValue % 3600) / 60
                    )}m`
                  : "";
              return {
                ...workout,
                date: day.workoutdate,
                readableDuration: formattedDuration,
                structure: workout.workout_doc,
                isHistorical: true
              };
            })
          }))
        }))
      : [];

    // Just pass through previousActivities array as-is
    const previousActivities = data.previousActivities || [];

    console.log("Processed data:", {
      currentWorkouts: updatedWorkouts.length,
      historicalWorkouts: processedHistory.length,
      previousActivitiesCount: previousActivities.length
    });

    return {
      ...data,
      workouts: updatedWorkouts,
      workoutHistory: processedHistory,
      weeklyLoads: data.weeklyLoads || [],
      currentWeekSummary: data.currentWeekSummary || null,
      // Expose the new field
      previousActivities
    };
  };

  const processedData = data ? addDateAndDurationToWorkouts(data) : null;

  const value = {
    data: processedData,
    error,
    plan: data?.plan,
    weeklyLoads: data?.weeklyLoads || [],
    currentWeekSummary: data?.currentWeekSummary,
    // Provide the previousActivities for external use
    previousActivities: processedData?.previousActivities || []
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};