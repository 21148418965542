import React from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, alpha } from "@mui/material";
import CalendarEventTopStrip from "./CalendarEventTopStrip";
import moment from "moment";

const CalendarEvent = ({ event, setOpenWorkoutView, setSelectedEvent, marginBottom }) => {
  const theme = useTheme();

  console.log('Rendering calendar event:', {
    title: event.title,
    date: event.date,
    isHistorical: event.isHistorical
  });

  const eventStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    width: "100%",
    borderRadius: "5px",
    padding: "0",
    backgroundColor: event.isHistorical
      ? alpha(theme.palette.info.light, 0.1)
      : event.eventtype === "activity"
        ? theme.palette.eventActivityBackground.main
        : theme.palette.eventOtherBackground.main,
    cursor: "pointer",
    marginBottom: marginBottom,
    transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease",
    overflow: "hidden",
    border: event.isHistorical
      ? `1px solid ${alpha(theme.palette.info.main, 0.3)}`
      : 'none',
  };

  const hoverStyle = {
    backgroundColor: event.isHistorical
      ? alpha(theme.palette.info.light, 0.2)
      : event.eventtype === "activity"
        ? theme.palette.eventActivityBackground.hover
        : theme.palette.eventOtherBackground.hover,
    transform: "scale(1.02)",
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
  };

  const handleEventClick = () => {
    console.log('Event clicked:', {
      title: event.title,
      isHistorical: event.isHistorical,
      type: event.eventtype
    });

    const formatDuration = (durationInSeconds) => {
      if (!durationInSeconds) return null;
      const duration = moment.duration(durationInSeconds, 'seconds');
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      return `${hours > 0 ? `${hours}h ` : ""}${minutes}m`;
    };

    setSelectedEvent({
      ...event,
      duration: formatDuration(
        event.eventtype === "activity" ? event.movingTime : event.duration * 60
      ),
      type: event.eventtype === "pastworkout" || !event.eventtype
        ? "workout"
        : event.eventtype,
      loads: {
        training_load: event.training_load,
        power_load: Math.round(event.tss),
        pace_load: Math.round(event.pace_load),
        heart_rate_load: Math.round(event.heart_rate_load),
      },
    });
    setOpenWorkoutView(true);
  };

  const loadValue = Math.round(event.training_load || event.load_edited || 0);
  const loadText = `Load: ${loadValue}`;

  return (
    <div
      style={eventStyle}
      onClick={handleEventClick}
      onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = eventStyle.backgroundColor;
        e.currentTarget.style.transform = "none";
        e.currentTarget.style.boxShadow = "none";
      }}
    >
      <CalendarEventTopStrip event={event} />
      {loadValue > 0 && (
        <div
          style={{
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            padding: "1px 4px",
            fontSize: "0.6rem",
            textAlign: "center",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {loadText}
        </div>
      )}
      <div style={{ padding: "4px" }}>
        <Typography
          variant="caption"
          display="block"
          component="div"
          style={{
            fontSize: "0.75rem",
            color: theme.palette.text.secondary,
          }}
        >
          {event.title}

        </Typography>
      </div>
    </div>
  );
};

export default CalendarEvent;