import React from "react";
import { Typography, useTheme } from "@mui/material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import InfoIcon from "@mui/icons-material/Info";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import moment from "moment";

/**
 * Returns a matching icon for the main event type (woType)
 * or subtype (e.g., 'Core'). If no recognized type, returns
 * SelfImprovementIcon for activities or null for plan events.
 */
const getIconForEventType = (eventType, eventObj) => {
  // Check for 'Core' subtype first
  const lowerSubtype = (eventObj.subtype || "").toLowerCase();
  if (lowerSubtype === "core") {
    return <AccessibilityNewIcon fontSize="small" />;
  }

  switch ((eventType || "").toLowerCase()) {
    case "run":
    case "running":
      return <DirectionsRunIcon fontSize="small" />;
    case "ride":
    case "cycling":
      return <DirectionsBikeIcon fontSize="small" />;
    case "swim":
    case "swimming":
      return <PoolIcon fontSize="small" />;
    case "strength":
      return <FitnessCenterIcon fontSize="small" />;
    case "information":
      return <InfoIcon fontSize="small" />;
    default:
      // Return SelfImprovementIcon as a placeholder for unknown activity types
      return eventObj.eventtype === "activity" ? (
        <SelfImprovementIcon fontSize="small" />
      ) : null;
  }
};

const formatDistance = (distance) =>
  distance ? `${(parseFloat(distance) / 1000).toFixed(0)}km` : "";

const formatTime = (seconds) => {
  if (!seconds) return "";
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  return hours > 0 ? `${hours}h${minutes % 60}m` : `${minutes % 60}m`;
};

const formatDuration = (durationInSeconds) => {
  const duration = moment.duration(durationInSeconds, "seconds");
  return (
    Math.floor(duration.asHours()) +
    moment.utc(duration.asMilliseconds()).format(":mm:ss")
  );
};

const formatSubtype = (subtype) => {
  const normalizedSubtype = (subtype || "").toLowerCase();
  if (normalizedSubtype === "longendurance") {
    return "Endurance";
  } else if (normalizedSubtype === "shortendurance") {
    return "Endurance";
  }
  return subtype || "";
};

const CalendarEventTopStrip = ({ event }) => {
  const theme = useTheme();
  const containerStyle = {
    backgroundColor:
      event.eventtype === "activity" ? "#c8e6c9" : theme.palette.grey[300],
    display: "flex",
    alignItems: "center",
    fontSize: "1.0rem",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "5px 5px 0 0",
    padding: "2px 4px",
    position: "relative",
    height: "24px",
  };

  const iconStyle = {
    position: "absolute",
    left: "4px",
    top: "50%",
    transform: "translateY(-50%)",
  };

  const textContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  };

  const getEventInfo = () => {
    if (event.eventtype === "activity") {
      return (
        <>
          {formatTime(event.movingTime)}
          {event.distance && (
            <span style={{ marginLeft: "8px", color: theme.palette.text.secondary }}>
              {formatDistance(event.distance)}
            </span>
          )}
        </>
      );
    } else if (
      event.eventtype !== "activity" &&
      Number.isFinite(event.duration)
    ) {
      return formatDuration(event.duration * 60);
    }
    return "";
  };

  return (
    <div style={containerStyle}>
      <div style={iconStyle}>{getIconForEventType(event.woType, event)}</div>
      <div style={textContainerStyle}>
        <Typography
          variant="caption"
          style={{
            fontSize: "0.75rem",
            color: theme.palette.text.primary,
            fontWeight: "bold",
          }}
        >
          {formatSubtype(event.subtype)} {getEventInfo()}
        </Typography>
      </div>
    </div>
  );
};

export default CalendarEventTopStrip;