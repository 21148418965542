import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import * as d3 from "d3";
import { useD3 } from "../hooks/useD3";

function BarChart({
  ChartWidth,
  height,
  workoutDoc,
  yAxisUnit,
  currentMetric,
  showLegends = true,
}) {
  const containerRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setParentWidth(containerRef.current.getBoundingClientRect().width);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    updateWidth(); // Initial update

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  /**
   * Calculates total duration from the steps array in workoutDoc.
   */
  function calculateTotalDuration(doc) {
    let totalDuration = 0;
    if (doc && Array.isArray(doc.steps)) {
      doc.steps.forEach((step) => {
        if (step.reps && Array.isArray(step.steps)) {
          // If repeated block
          const nestedDuration = step.steps.reduce(
            (acc, nestedStep) => acc + (nestedStep.duration || 0),
            0
          );
          totalDuration += nestedDuration * step.reps;
        } else {
          totalDuration += step.duration || 0;
        }
      });
    }
    return totalDuration;
  }

  /**
   * Safely fetches the numeric value for the chosen metric from the step.
   * Returns 0 if missing.
   */
  function getStepMetricValue(stepObj, metric) {
    if (!stepObj || !metric) return 0;
    const metricLower = metric.toLowerCase();
    if (!stepObj[metricLower]) {
      // There's no object for that metric
      return 0;
    }
    const value = stepObj[metricLower].value;
    return typeof value === "number" ? value : 0;
  }

  const totalDuration = calculateTotalDuration(workoutDoc);
  let interval = 15 * 60; // Interval for tick marks, 15 minutes
  let numTicks = Math.ceil(totalDuration / interval);

  const [loadedData, setLoadedData] = useState([]);

  useEffect(() => {
    if (!workoutDoc || !currentMetric) {
      setLoadedData([]);
      return;
    }

    // The multiplier for converting the step's percentage to actual numeric
    const newDataSet = [];
    const metricValueMultiplier = getMetricValueMultiplier(workoutDoc);

    let accumulatedDuration = 0;

    // Go through each step, flatten if repeated
    if (workoutDoc && Array.isArray(workoutDoc.steps)) {
      workoutDoc.steps.forEach((step) => {
        if (step.reps && Array.isArray(step.steps)) {
          for (let i = 0; i < step.reps; i++) {
            step.steps.forEach((childStep) => {
              const stepValue = getStepMetricValue(childStep, currentMetric);
              newDataSet.push([
                accumulatedDuration,
                (metricValueMultiplier * stepValue) / 100,
                childStep.duration || 0,
              ]);
              accumulatedDuration += childStep.duration || 0;
            });
          }
        } else {
          // Single step
          const stepValue = getStepMetricValue(step, currentMetric);
          newDataSet.push([
            accumulatedDuration,
            (metricValueMultiplier * stepValue) / 100,
            step.duration || 0,
          ]);
          accumulatedDuration += step.duration || 0;
        }
      });
    }

    setLoadedData(newDataSet);
  }, [workoutDoc, currentMetric, totalDuration]);

  /**
   * Returns the factor to multiply step's value% by:
   * - Power -> ftp
   * - HR    -> thresholdHr
   * - Pace  -> we consider 100 as base, or threshold pace if the code is adjusted
   */
  function getMetricValueMultiplier(doc) {
    switch (currentMetric) {
      case "Power":
        return doc?.ftp || 1;
      case "HR":
        return doc?.thresholdHr || 1;
      case "Pace":
        // For now, just use 100 to represent the percentage scale
        return 100;
      default:
        return 1;
    }
  }

  const ref = useD3(
    (svg) => {
      svg.selectAll("*").remove(); // Clear

      if (!loadedData || loadedData.length === 0 || totalDuration <= 0) {
        return;
      }

      let margin = showLegends
        ? { top: 10, right: 10, bottom: 30, left: 50 }
        : { top: 0, right: 10, bottom: 0, left: 30 };

      const effectiveWidth = ChartWidth || parentWidth;
      const width = effectiveWidth - margin.left - margin.right;
      const adjustedHeight = (height || 300) - margin.top - margin.bottom;

      // Create main group
      const g = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // X scale: 0 -> totalDuration
      const xScale = d3
        .scaleLinear()
        .domain([0, totalDuration])
        .range([0, width]);

      // Y scale
      const maxY = d3.max(loadedData, (d) => d[1]) || 1;
      const yScale = d3
        .scaleLinear()
        .domain([0, maxY])
        .range([adjustedHeight, 0]);

      // X axis ticks
      let tickValues = Array.from({ length: numTicks }, (_, i) => i * interval);

      // Render X axis if needed
      if (showLegends) {
        g.append("g")
          .attr("transform", `translate(0, ${adjustedHeight})`)
          .call(
            d3
              .axisBottom(xScale)
              .tickValues(tickValues)
              .tickFormat((d) => secondsToTime(d))
          )
          .selectAll("text")
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", "rotate(-90)");

        g.append("text")
          .attr("transform", `translate(${width / 2}, ${adjustedHeight + 60})`)
          .style("text-anchor", "middle")
          .text("Elapsed Time (h:m)");

        // Y axis
        g.append("g").call(d3.axisLeft(yScale).ticks(4));

        g.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - margin.left)
          .attr("x", 0 - adjustedHeight / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text(yAxisUnit || "");
      }

      // Render bars
      g.selectAll(".bar")
        .data(loadedData)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]))
        .attr("width", (d) => (d[2] * width) / totalDuration)
        .attr("height", (d) => adjustedHeight - yScale(d[1]))
        .attr("fill", (d) => getBarColor(d[1], workoutDoc));
    },
    [loadedData, totalDuration, parentWidth, height, showLegends]
  );

  /**
   * Helper that returns the color for a bar based on the y value.
   * We attempt to match it to zoneTimes if available, or fallback color.
   */
  function getBarColor(value, doc) {
    if (!doc?.zoneTimes) {
      // fallback color
      return "steelblue";
    }
    for (let i = 0; i < doc.zoneTimes.length; i++) {
      const z = doc.zoneTimes[i];
      if (value >= z.minWatts && value < z.maxWatts) {
        return z.color || "steelblue";
      }
    }
    return "steelblue";
  }

  // Convert seconds to h:m or m
  function secondsToTime(e) {
    var h = Math.floor(e / 3600).toString();
    var m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0");
    if (parseInt(h, 10) > 0) {
      return `${h}:${m}`;
    }
    return m + "m";
  }

  return (
    <div ref={containerRef}>
      <svg
        ref={ref}
        width={ChartWidth || parentWidth}
        height={height || 300}
      ></svg>
    </div>
  );
}

export default BarChart;