import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import CalendarUpcomingTable2 from "./subview/calendarMobile";
import CalendarDesktop from "./subview/calendarDesktop";
import Loading from "../Loading";
import WeeklyTrainingSummary from "./WeeklyTraining/WeeklyTrainingSummary";
import { useTheme } from "@mui/material/styles";
import { useCalendarData } from "./subview/calendarDataProvider";

function Calendar({ accessToken }) {
  const { user } = useAuth0();
  const isMobile = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const { data, error, weeklyLoads, currentWeekSummary } = useCalendarData();

  // If no data yet, show loading
  if (!data) {
    return <Loading />;
  }

  // Basic layout: On mobile, stack the WeeklyTrainingSummary and the upcoming table.
  // On desktop, show them side by side.
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "80vh",
        padding: { xs: 1, md: 2 },
        boxSizing: "border-box",
      }}
      className="calendar_section"
    >
      {isMobile ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <WeeklyTrainingSummary trainingPlan={data?.weeklyLoads} />
          </Grid>
          <Grid item xs={12}>
            <CalendarUpcomingTable2 user={user} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              overflowY: "auto",
              maxHeight: "calc(100vh - 120px)",
              pr: 1,
            }}
          >
            <WeeklyTrainingSummary trainingPlan={data?.weeklyLoads} />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              overflowY: "auto",
              maxHeight: "calc(100vh - 120px)",
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <CalendarDesktop user={user} accessToken={accessToken} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default withAuthenticationRequired(Calendar, {
  onRedirecting: () => <Loading />,
});